<!--
 * @Author: LazyQ
 * @Date: 2020-04-14 16:49:10
 * @LastEditTime: 2020-04-17 23:48:31
 * @LastEditors: LazyQ
 -->
 <style lang="less" scoped>
.delete {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(68, 125, 245, 1);
  &:hover {
    cursor: pointer;
  }
}
</style>
<template>
  <span class="delete" @click="deleteWaring">删除</span>
</template>

<script>
export default {
  name: "TableDelete",
  data() {
    return {};
  },
  created() {
    this.title = this.$route.meta.title;
  },
  methods: {
    deleteWaring() {
      const that = this;
      this.$confirm({
        title: "友情提示",
        content: "是否删除该项？",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          that.$emit("comfirm");
        },
        onCancel() {}
      });
    }
  }
};
</script>